import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import './app.css'
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Product from "./pages/Product";
import ProductDetail from "./pages/ProductDetail";


function App() {
  return (
    <>
      <div className="container">
        <div className='contNav'>
          <Navbar />
        </div>
        <div className="wapContainer">
          <a href="https://wa.me/5491132317962" target="_blank" rel="noreferrer"><img src="/icons/wap-icon.svg" alt="whats app icon" /></a>
          
        </div>
        <div className='content'>
          <Routes >
            <Route path="/" element={<Home />} />
            <Route path=":categoria" element={<Product />} />
            <Route path=":categoria/:productId" element={<ProductDetail />} />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
          </Routes>
        </div>
        <Footer />
      </div>

    </>
  );
}

export default App;
