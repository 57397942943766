import Button from '../components/button/Button';
// import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import classes from './home.module.css'
import { Link } from 'react-router-dom';
import { ParallaxProvider, ParallaxBanner, Parallax } from 'react-scroll-parallax';



export default function Home() {
    const { ref: classic, inView } = useInView({ delay: 100 })
    const { ref: zocalo, inView: zocaloView } = useInView({ delay: 100 })
    const { ref: wood, inView: woodView } = useInView({ delay: 100 })
    const { ref: giant, inView: giantView } = useInView({ delay: 100 })
    const { ref: siding, inView: sidingView } = useInView({ delay: 100 })

    console.log("ggg", inView)

    return (
        <>
            <ParallaxProvider>
                <section className={classes.page} >
                    <div className={classes.bgFloor}>
                        <img className={classes.img} src="./img/pisoalta200.jpg" alt="Fondo de piso" />

                    </div>
                    <div className={classes.container}>
                        <div className={classes.container2}>
                            <div className={classes.greeting}>
                                <div className={classes.circle}></div>
                                <p>HOLA!</p>
                            </div>
                            <div className={`${classes.titleCont} ${classes.paddingFromBtn}`}>
                                <div className={classes.title}>
                                    <h1 className={classes.titleAnimation}>
                                        Select
                                    </h1>
                                </div>
                                <div className={classes.title}>
                                    <h1 className={classes.titleAnimation}>
                                        flooring
                                    </h1>
                                </div>
                                <div className={classes.title}>
                                    <h3>
                                        maxima resistencia
                                    </h3>
                                </div>
                            </div>
                            <Button text={'+info'} link={'/selectFlooring'} />

                            <Link to='#'><img className={classes.icon} src="./img/icon-flecha.png" alt='icono flecha' /></Link>
                        </div>

                    </div>
                </section>
                <section className={classes.secP} id="#clasicFlooring">
                    <div className={classes.container}>
                        <Parallax speed={5}>
                            <div className={classes.paralaxContainer}>
                                <img className={classes.imgCF} src="./img/foto3.jpg" alt="" />

                            </div>
                        </Parallax>

                        {/* <img className={classes.imgClassic} src="./img/FOTO3.jpg" alt="Imagen Classic Flooring" /> */}
                        <Parallax speed={5}>
                            <div className={classes.infoClasic}>
                                <div ref={classic} className={classes.title}>
                                    <h2 className={`${inView ? `${classes.titleAnimation} ${classes.titleTextItalic}` : `${classes.titleTextItalic}`}`} >
                                        Classic
                                    </h2>
                                </div>
                                <div className={classes.title}>
                                    <h2 className={`${inView ? `${classes.titleAnimation} ${classes.titleText}` : `${classes.titleTextItalic}`}`}>
                                        <span> Flooring</span>
                                    </h2>
                                </div>

                                <p className={classes.paddingFromBtn}>Make it your own <br /> Make it your home</p>
                                <Button text={'+info'} link={'/classicFlooring'} />
                            </div>
                        </Parallax>
                    </div>
                </section>
                <section className={`${classes.secP} ${classes.bgDarkGray}`}>
                    <div className={classes.container}>
                        <Parallax speed={3}>
                            <img className={classes.imgZocalos} src="./img/zocalos.jpg" alt="Imagen Seccion zocalos" />
                        </Parallax>
                        <div className={classes.infoZocalos} >
                            <Parallax speed={3}>
                                <div className={`${classes.zocaloTitle} ${classes.paddingFromBtn}`}>
                                    <div>
                                        <div ref={zocalo} className={classes.title}>
                                            <h2 className={`${zocaloView ? `${classes.titleAnimation} ${classes.titleTextItalic}` : `${classes.titleTextItalic}`}`} >
                                                Zocalos
                                            </h2>
                                        </div>
                                        <div className={classes.title}>
                                            <h2 className={`${zocaloView ? `${classes.titleAnimation} ${classes.titleText}` : `${classes.titleTextItalic}`}`}>
                                                <span> de PVC</span>
                                            </h2>
                                        </div>
                                    </div>
                                    <h3 className={`${classes.subTituloSec} ${classes.whiteFont}`}>
                                        Para una <br /> terminación <br /> perfecta
                                    </h3>
                                </div>
                            </Parallax>
                            <Parallax speed={3}>
                                <Button text={'+info'} link={'/zocalosPvc'} />

                            </Parallax>

                        </div>

                        <div className={classes.detalleZocalos}>
                            <Parallax speed={5}>
                                <img src="/img/sup-resistente.png" alt="" />
                                <p>Super <br /> resistente</p>
                            </Parallax>
                        </div>

                    </div>
                </section>
                <section className={classes.wall}>
                    <div className={classes.containerWall}>
                        <div className={classes.infoWall}>
                            <div>
                                <div ref={wood} className={classes.title}>
                                    <h2 className={`${woodView ? `${classes.titleAnimation} ${classes.titleTextItalic} ${classes.blackFont}` : `${classes.titleTextItalic}`}`} >
                                        Wood
                                    </h2>
                                </div>
                                <div className={classes.title}>
                                    <h2 className={`${woodView ? `${classes.titleAnimation} ${classes.titleText} ${classes.paddingFromBtn} ${classes.blackFont}` : `${classes.titleTextItalic}`}`}>
                                        <span className={classes.paddingFromBtn}> Panels</span>
                                    </h2>
                                </div>
                                <Button className={classes.botonWood} text={'+info'} link={'/woodPanels'} />
                            </div>
                            <h3 className={`${classes.subTituloSec} ${classes.blackFont}`}>
                                Revestí paredes <br className={classes.salto} /> y techos en el día
                            </h3>
                        </div>
                        <div className={classes.wallImgCont}>
                            <ParallaxBanner
                                layers={[{ image:'./img/FOTO5WoodPanles.jpg', speed: -6 }]}
                                className={classes.wallPic}
                            />
                            {/* <img className={classes.wallPic} src="./img/FOTO 5 Wood Panles.jpg" alt="" /> */}
                            <div className={classes.wallPicMuestraContenedor}>
                                <img src="./Wood Panels/WP-03-Roble.webp" alt="Panel muestra" />
                            </div>
                        </div>
                    </div>
                    <div className={classes.giantWall}>
                        <div className={classes.gWallInfo}>
                            <h3>
                                Big size, <br /> efecto de "Pieza Única"
                            </h3>
                            <div>
                                <div ref={giant} className={classes.title}>
                                    <h2 className={`${giantView ? `${classes.titleAnimation} ${classes.titleTextItalic} ${classes.blackFont}` : `${classes.titleTextItalic}`}`} >
                                        Giant Wall
                                    </h2>
                                </div>
                                <div className={classes.title}>
                                    <h2 className={`${giantView ? `${classes.titleAnimation} ${classes.titleText} ${classes.paddingFromBtn} ${classes.blackFont}` : `${classes.titleTextItalic}`}`}>
                                        <span className={classes.paddingFromBtn}> Panels</span>
                                    </h2>
                                </div>
                                <Button className={classes.btn} text={'+info'} link={'/giantWallPanels'} />
                            </div>
                        </div>
                        <img className={classes.gWallPic} src="/img/FOTO n 6 Giant Wall Panels 1920 x 1080.jpg" alt="" />

                    </div>
                    <div className={classes.containerWall}>
                        <div className={classes.infoWallSiding}>
                            <div>
                                <div ref={siding} className={classes.title}>
                                    <h2 className={`${sidingView ? `${classes.titleAnimation} ${classes.titleTextItalic} ${classes.blackFont}` : `${classes.titleTextItalic}`}`} >
                                        Siding - WPC
                                    </h2>
                                </div>
                                <div className={classes.title}>
                                    <h2 className={`${sidingView ? `${classes.titleAnimation} ${classes.titleText} ${classes.paddingFromBtn} ${classes.blackFont}` : `${classes.titleTextItalic}`}`}>
                                        <span className={classes.paddingFromBtn}> Exterior</span>
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <Button className={classes.botonWood} text={'+info'} link={'/sidingdeexterior'} />
                        <div className={classes.wallImgCont}>
                            <div className={classes.contSidingImg}>
                                <img className={classes.wallPic1} src="./img/siding wpc 3.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </ParallaxProvider>

        </>
    )
}
