const db = [
    {
        id: 1,
        link: 'selectFlooring',
        categoria: 'select flooring',
        descripcion: 'Apto tanto para uso comercial como residencial, incluidos baños y cocinas ya que son resistentes al agua. Su textura logra una apariencia natural y real, y los hace confortables al caminar. Está linea cuenta con 18 diseños únicos: 14 símil madera y 4 símil piedra. Los mismos tienen un espesor de 5.5 mm y cuentan con una capa de uso de 0,5 mm, lo cual los hace sumamente resistentes al desgaste e impactos; es decir, aptos alto tránsito. Gracias a su sistema de encastre “click” en los cuatro lados, se coloca de manera ágil sobre cualquier superficie firme y lisa. Su colocación no requiere de ningún tipo de adhesivos tóxicos, por lo que se evita una obra húmeda y duradera. En su parte inferior cuentan con una manta acústica y térmica que corrige pequeñas imperfecciones de la superficie existente. Para complementar nuestros pisos y lograr una terminación perfecta, también contamos con nuestra línea de zócalos en tonalidades similares a los pisos o blancas en distintas alturas. ',
        destacado: [
            {
                id: 1,
                nombre: 'resistente al agua',
                img: 'resistenteAlAgua.svg',
                link: 'wr-08-roble'
            },
            {
                id: 2,
                nombre: 'facil de instalar',
                img: 'FacilInstalar.svg'
            },
            {
                id: 3,
                nombre: 'pisos silenciosos',
                img: 'PisosSilenciosos.svg'
            },
            {
                id: 4,
                nombre: 'super resistente',
                img: 'SuperResistentes.svg'
            }

        ],
        productos: [
            {
                id: 1,
                nombre: 'S-1 Cementi Silver',
                material: '100% pvc virgen',
                simil: 'Porcelanatto',
                medidas: '305mm x 610mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'B-80 STEEL',
                        img: 'Zocalos de PVC/B-80-Steel-1536x1024.webp'
                    }
                ],
                img: 'Select Flooring/s-1-cementi-silver.jpg',
                link: 's-1-cementi-silver'
            },
            {
                id: 2,
                nombre: 'S-2 NATURAL STONE',
                material: '100% pvc virgen',
                simil: 'Porcelanatto',
                medidas: '305mm x 610mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    }
                ],
                img: 'Select Flooring/s-2-natural-stone.jpg',
                link: 's-2-natural-stone'

            },
            {
                id: 4,
                nombre: 'S-3 Cementi Grigio',
                material: '100% pvc virgen',
                simil: 'Porcelanatto',
                medidas: '305mm x 610mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'B-80 STEEL',
                        img: 'Zocalos de PVC/B-80-Steel-1536x1024.webp'
                    }
                ],
                img: 'Select Flooring/S-3 Cementi Grigio I.jpg',
                link: 's-3-cementi-grigio'

            },
            {
                id: 5,
                nombre: 'S-4 Light Beige  Loose Lay',
                material: '100% pvc virgen',
                simil: 'Porcelanatto',
                medidas: '450mm x 450mm',
                espesor: '5mm',
                capaUso: '0,5mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    }
                ],
                img: 'Select Flooring/S-4-Light-Beige-compressor.webp',
                link: 's-4'

            },
            {
                id: 6,
                nombre: 'W-01 BLANCO',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'W-01 Blanco',
                        img: 'Zocalos de PVC/W-01 Blanco Zocalo.jpg'
                    }
                ],
                img: 'Select Flooring/W-01 Blanco I.jpg',
                link: 'w-01-blanco'

            },
            {
                id: 7,
                nombre: 'W-03 GRIS',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'W-21',
                        img: 'Zocalos de PVC/W-21-Zocalo-Gris-Titanio-1536x1024.webp'
                    }
                ],
                img: 'Select Flooring/W-03 Gris I.jpg',
                link: 'w-03-gris'
            },
            {
                id: 8,
                nombre: 'W-04 CHOCOLATE',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'W-04',
                        img: 'Zocalos de PVC/W-04 web baja.jpg'
                    },
                    {
                        nombre:'W-06',
                        img: 'Zocalos de PVC/W-06-Zocalo-Rustico-1536x1024.webp'
                    }
                ],
                img: 'Select Flooring/W-04 Chocolate.jpg',
                link: 'w-04-chocolate'
            },
            {
                id: 9,
                nombre: 'W-05 ROBLE CAL',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'C-120 wood',
                        img: 'Zocalos de PVC/C-120-Wood1-1536x1024.webp'
                    },
                    {
                        nombre:'W-05',
                        img: 'Zocalos de PVC/W-05 Roble Cal Zocalo.jpg'
                    },
                    {
                        nombre:'WR-17',
                        img: 'Zocalos de PVC/WR-17 Zocalo Pino Natural ALTA (1).jpg'
                    }
                ],
                img: 'Select Flooring/W-05 Roble Cal I (1).jpg',
                link: 'w-05-roble-cal'
            },
            {
                id: 10,
                nombre: 'W-06 RUSTICO',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'C-120 wood',
                        img: 'Zocalos de PVC/C-120-Wood1-1536x1024.webp'
                    },
                    {
                        nombre:'W-04',
                        img: 'Zocalos de PVC/W-04 web baja.jpg'
                    },
                    {
                        nombre:'W-06',
                        img: 'Zocalos de PVC/W-06-Zocalo-Rustico-1536x1024.webp'
                    },
                    {
                        nombre:'WR-16',
                        img: 'Zocalos de PVC/WR-16 Petiribi Zocalo.jpg'
                    }
                ],
                img: 'Select Flooring/W-06 Rustico I.jpg',
                link: 'w-06-rustico'
            },
            {
                id: 11,
                nombre: 'W-07 TANGO',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'W-01 BLANCO',
                        img: 'Zocalos de PVC/W-01 Blanco Zocalo.JPG'
                    }
                ],
                img: 'Select Flooring/W-07 Tango.jpg',
                link: 'w-07-tango'
            },
            {
                id: 12,
                nombre: 'W-08 ROBLE',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'C-120 wood',
                        img: 'Zocalos de PVC/C-120-Wood1-1536x1024.webp'
                    },
                    {
                        nombre:'W-06',
                        img: 'Zocalos de PVC/W-06-Zocalo-Rustico-1536x1024.webp'
                    },
                    {
                        nombre:'W-08',
                        img: 'Zocalos de PVC/W-08 09 Roble y Maple Zocalo.jpg'
                    },
                    {
                        nombre:'W-10',
                        img: 'Zocalos de PVC/W-10-Incienso-Zocalos--1536x1024.webp'
                    }
                ],
                img: 'Select Flooring/W-08 Roble.jpg',
                link: 'w-08-roble'
            },
            {
                id: 13,
                nombre: 'W-09 MAPLE',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'W-08',
                        img: 'Zocalos de PVC/W-08 09 Roble y Maple Zocalo.jpg'
                    }
                ],
                img: 'Select Flooring/W-09 Maple I.jpg',
                link: 'w-09-maple'
            },
            {
                id: 14,
                nombre: 'W-10 INCIENSO',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion:[
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'C-120 wood',
                        img: 'Zocalos de PVC/C-120-Wood1-1536x1024.webp'
                    },
                    {
                        nombre:'W-04',
                        img: 'Zocalos de PVC/W-04 web baja.jpg'
                    },
                    {
                        nombre:'W-06',
                        img: 'Zocalos de PVC/W-06-Zocalo-Rustico-1536x1024.webp'
                    },
                    {
                        nombre:'WR-16',
                        img: 'Zocalos de PVC/WR-16 Petiribi Zocalo.jpg'
                    },
                    {
                        nombre:'W-10',
                        img: 'Zocalos de PVC/W-10-Incienso-Zocalos--1536x1024.webp'
                    }
                ],
                img: 'Select Flooring/W-10 Incienso I.jpg',
                link: 'w-10-incienso'
            },
            {
                id: 15,
                nombre: 'W-11 VISON',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion:[
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    }
                ],
                img: 'Select Flooring/W-11 Vison.jpg',
                link: 'w-11-vision'
            },
            {
                id: 16,
                nombre: 'W-12 CEREZO',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion:[
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'W-12',
                        img: 'Zocalos de PVC/W-12-web-baja.webp'
                    }
                ],
                img: 'Select Flooring/W-12 Cerezo.jpg',
                link: 'w-12-cerezo'
            },
            {
                id: 17,
                nombre: 'W-14 ROBLE NATURAL',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion:[
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'W-05',
                        img: 'Zocalos de PVC/W-05 Roble Cal Zocalo.jpg'
                    },
                    {
                        nombre:'WR-17',
                        img: 'Zocalos de PVC/WR-17 Zocalo Pino Natural ALTA (1).jpg'
                    }
                ],
                img: 'Select Flooring/W-14 Roble Natural I.jpg',
                link: 'w-14-roble-natural'
            },
            {
                id: 18,
                nombre: 'W-20 Roble Americano',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion:[
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    }
                ],
                img: 'Select Flooring/W-20-Roble-Americano-compressor.jpg',
                link: 'w-20-roble-americano'
            },
            {
                id: 19,
                nombre: 'W-21 GRIS TITANIO',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '5,5mm',
                capaUso: '0,5mm',
                combinacion:[
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'W-21',
                        img: 'Zocalos de PVC/W-21-Zocalo-Gris-Titanio-1536x1024.webp'
                    },
                    {
                        nombre:'WR-17',
                        img: 'Zocalos de PVC/WR-17 Zocalo Pino Natural ALTA (1).jpg'
                    }
                ],
                img: 'Select Flooring/W-21-Gris-Titanio-compressor.jpg',
                link: 'w-21-gris-titanio'
            },

        ]
    },
    {
        id: 2,
        link: 'classicFlooring',
        categoria: 'classic flooring',
        descripcion: 'Apto para ambientes residenciales, incluidos baños y cocinas ya que son resistentes al agua. Su textura logra una apariencia natural y real, y los hace confortables al caminar. Contamos con 6 diseños únicos símil madera de esta línea, los cuales tienen un espesor de 4,7 mm. Gracias a su sistema de encastre “click” en los cuatro lados, se coloca de manera ágil sobre cualquier superficie firme y lisa. Su colocación no requiere de ningún tipo de adhesivos tóxicos, por lo que se evita una obra húmeda y duradera.En su parte inferior cuentan con una manta acústica y térmica que corrige pequeñas imperfecciones de la superficie existente. Para complementar nuestros pisos y lograr una terminación perfecta, también contamos con nuestra línea de zócalos en tonalidades similares a los pisos o blancas en distintas alturas.',
        destacado: [
            {
                id: 1,
                nombre: 'resistente al agua',
                img: 'resistenteAlAgua.svg'
            },
            {
                id: 2,
                nombre: 'facil de instalar',
                img: 'FacilInstalar.svg'
            },
            {
                id: 3,
                nombre: 'pisos silenciosos',
                img: 'PisosSilenciosos.svg'
            },
            {
                id: 4,
                nombre: 'super resistente',
                img: 'SuperResistentes.svg'
            }

        ],
        productos: [
            {
                id: 1,
                nombre: 'WR-05 ROBLE CAL',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '4,7mm',
                capaUso: '0,2mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'C-120 wood',
                        img: 'Zocalos de PVC/C-120-Wood1-1536x1024.webp'
                    },
                    {
                        nombre:'W-08',
                        img: 'Zocalos de PVC/W-08 09 Roble y Maple Zocalo.jpg'
                    },
                    {
                        nombre:'W-10',
                        img: 'Zocalos de PVC/W-10-Incienso-Zocalos--1536x1024.webp'
                    }
                ],
                img: 'Classic Flooring/w05_roble_cal.jpg',
                link: 'wr-05-roble-cal'
            },
            {
                id: 2,
                nombre: 'WR-08 ROBLE',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '4,7mm',
                capaUso: '0,2mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'C-120 wood',
                        img: 'Zocalos de PVC/C-120-Wood1-1536x1024.webp'
                    },
                    {
                        nombre:'W-06',
                        img: 'Zocalos de PVC/W-06-Zocalo-Rustico-1536x1024.webp'
                    },
                    {
                        nombre:'W-08',
                        img: 'Zocalos de PVC/W-08 09 Roble y Maple Zocalo.jpg'
                    },
                    {
                        nombre:'W-10',
                        img: 'Zocalos de PVC/W-10-Incienso-Zocalos--1536x1024.webp'
                    }
                ],
                img: 'Classic Flooring/WR-08 Roble.jpg',
                link: 'wr-08-roble'

            },
            {
                id: 3,
                nombre: 'WR-16 PETIRIBI',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '4,7mm',
                capaUso: '0,2mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'WR-16',
                        img: 'Zocalos de PVC/WR-16 Petiribi Zocalo.jpg'
                    }
                ],
                img: 'Classic Flooring/WR-16 Petiribi I.jpg',
                link: 'wr-16-petiribi'

            },
            {
                id: 4,
                nombre: 'WR-17 PINO NATURAL',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '4,7mm',
                capaUso: '0,2mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'W-05',
                        img: 'Zocalos de PVC/W-05 Roble Cal Zocalo.jpg'
                    },
                    {
                        nombre:'WR-17',
                        img: 'Zocalos de PVC/WR-17 Zocalo Pino Natural ALTA (1).jpg'
                    }
                ],
                img: 'Classic Flooring/WR-17 Pino Natural I.jpg',
                link: 'wr-17-pino-natural'

            },
            {
                id: 5,
                nombre: 'WR-18 ABETO',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '4,7mm',
                capaUso: '0,2mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    },
                    {
                        nombre:'W-01 BLANCO',
                        img: 'Zocalos de PVC/W-01 Blanco Zocalo.JPG'
                    }
                ],
                img: 'Classic Flooring/WR-18 Abeto I.jpg',
                link: 'wr-18-abeto'

            },
            {
                id: 6,
                nombre: 'WR-19 ROBLE NÓRDICO',
                material: '100% pvc virgen',
                simil: 'Madera',
                medidas: '180mm x 1220mm',
                espesor: '4,7mm',
                capaUso: '0,2mm',
                combinacion: [
                    {
                        nombre:'B-80',
                        img: 'Zocalos de PVC/B-80-1536x1024.webp'
                    }
                ],
                img: 'Classic Flooring/WR-19 Roble Nordico I.jpg',
                link: 'wr-19-roble-nordico'
            }
        ]
    },
    {
        id: 3,
        link: 'zocalosPvc',
        categoria: 'zocalos de pvc',
        descripcion: 'Un zócalo es una unión elegante entre el suelo y la pared. Pero los zócalos son mucho más que eso. Le dan carácter a su interior, pueden crear una sensación óptica de mayor altura, son prácticos para esconder una junta descuidada entre el suelo y la pared. Son indispensables para cualquier Ambiente , ofrecemos zócalos de todos los modelos, colores y formatos, y para todos los estilos. De líneas rectas, rústicos, grandes, pequeños, llamativos o precisamente discretos. El material es resistente a golpes y al agua, y es sumamente ligero. Para una terminación perfecta los zócalos viene al tono para cada modelo de piso o si bien prefiere también contamos con una linea de color Blanco Mate',
        destacado: [
            {
                id: 1,
                nombre: 'resistente al agua',
                img: 'resistenteAlAgua.svg'
            },
            {
                id: 2,
                nombre: 'facil de instalar',
                img: 'FacilInstalar.svg'
            },
            {
                id: 3,
                nombre: 'Anti Polillas',
                img: 'AntiPolillas.svg'
            },
            {
                id: 4,
                nombre: 'super resistente',
                img: 'SuperResistentes.svg'
            }

        ],
        productos: [
            {
                id: 1,
                nombre: 'B-50',
                material: 'Poliuretano',
                simil: '',
                medidas: '5cm x 2,85mts',
                espesor: '1cm',
                img: 'Zocalos de PVC/b50_800x600.jpg',
                link: 'b-50'
            },
            {
                id: 2,
                nombre: 'B-70',
                material: 'Poliuretano',
                simil: '',
                medidas: '7cm x 2,85mts',
                espesor: '1cm',
                img: 'Zocalos de PVC/B-70_-compressor.webp',
                link: 'b-70'
            },
            {
                id: 3,
                nombre: 'B-75',
                material: 'Poliuretano',
                simil: '',
                medidas: '7,5cm x 2,85mts',
                espesor: '1,7cm',
                img: 'Zocalos de PVC/b75_800x600.webp',
                link: 'b-75'
            },
            {
                id: 4,
                nombre: 'B-80',
                material: 'Poliuretano',
                simil: '',
                medidas: '8cm x 2,85mts',
                espesor: '1,7cm',
                img: 'Zocalos de PVC/B-80-1536x1024.webp',
                link: 'b-80'
            },
            {
                id: 5,
                nombre: 'B-80 Steel',
                material: 'Poliuretano',
                simil: '',
                medidas: '8cm x 2,85mts',
                espesor: '1,7cm',
                img: 'Zocalos de PVC/B-80-Steel-1536x1024.webp',
                link: 'b-80-stell'
            },
            {
                id: 6,
                nombre: 'B-90',
                material: 'Poliuretano',
                simil: '',
                medidas: '9cm x 2,85mts',
                espesor: '1,6cm',
                img: 'Zocalos de PVC/b90_800x600.webp',
                link: 'b-90'
            },
            {
                id: 7,
                nombre: 'B-95',
                material: 'Poliuretano',
                simil: '',
                medidas: '9,5cm x 2,85mts',
                espesor: '2cm',
                img: 'Zocalos de PVC/b95_800x600.webp',
                link: 'b-95'
            },
            {
                id: 8,
                nombre: 'B-100',
                material: 'Poliuretano',
                simil: '',
                medidas: '10cm x 2,85mts',
                espesor: '1,3cm',
                img: 'Zocalos de PVC/B-100-1-1536x1024.webp',
                link: 'b-100'
            },
            {
                id: 9,
                nombre: 'B-120',
                material: 'Poliuretano',
                simil: '',
                medidas: '12cm x 2,85mts',
                espesor: '1,5cm',
                img: 'Zocalos de PVC/b120_800x600.webp',
                link: 'b-120'
            },
            {
                id: 10,
                nombre: 'C-120 (COBERTOR)',
                material: 'Poliuretano',
                simil: '',
                medidas: '12cm x 2,85mts',
                espesor: '2,2cm',
                img: 'Zocalos de PVC/c120_cobertor_800x600.jpeg',
                link: 'c-120'
            },
            {
                id: 35,
                nombre: 'C-120 WOOD (COBERTOR)',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '12cm x 2,85mts',
                espesor: '2,2cm',
                img: 'Zocalos de PVC/C-120-Wood1-1536x1024.webp',
                link: 'c-120wood-cobertor'
            },
            {
                id: 11,
                nombre: 'C-120 Steel (COBERTOR)',
                material: 'Poliuretano',
                simil: '',
                medidas: '12cm x 2,85mts',
                espesor: '2,2cm',
                img: 'Zocalos de PVC/C-120-Steel-1536x1024.webp',
                link: 'c-120-steel-cobertor'
            },

            {
                id: 13,
                nombre: 'W-01 Blanco',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '7,5cm x 2,85mts',
                espesor: '1,4cm',
                img: 'Zocalos de PVC/W-01 Blanco Zocalo.jpg',
                link: 'w-01-blanco-zocalo'
            },
            {
                id: 14,
                nombre: 'W-02 Pizarra',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '7,5cm x 2,85mts',
                espesor: '1,4cm',
                img: 'Zocalos de PVC/W-02-Pizarra-Z¦calo-compressor.webp',
                link: 'w-02-pizzarra-zocalo'
            },
            {
                id: 15,
                nombre: 'W-04 Chocolate',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '7,5cm x 2,85mts',
                espesor: '1,4cm',
                img: 'Zocalos de PVC/W-04 web baja.jpg',
                link: 'w-02-pizzarra-zocalo'
            },
            {
                id: 16,
                nombre: 'W-05 Roble',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '7,5cm x 2,85mts',
                espesor: '1,4cm',
                img: 'Zocalos de PVC/W-05 Roble Cal Zocalo.jpg',
                link: 'w-05-roble-cal-zocalo'
            },
            {
                id: 17,
                nombre: 'W-06 Rustico',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '8cm x 2,85mts',
                espesor: '1,4cm',
                img: 'Zocalos de PVC/W-06-Zocalo-Rustico-1536x1024.webp',
                link: 'w-06rustico'
            },
            {
                id: 18,
                nombre: 'W-08/09 Roble/Maple',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '7,5cm x 2,85mts',
                espesor: '1,4cm',
                img: 'Zocalos de PVC/W-08 09 Roble y Maple Zocalo.jpg',
                link: 'w-06rustico'
            },
            {
                id: 19,
                nombre: 'W-10 Incienso',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '8cm x 2,85mts',
                espesor: '1,4cm',
                img: 'Zocalos de PVC/W-10-Incienso-Zocalos--1536x1024.webp',
                link: 'w-10-incienso-zocalo'
            },
            {
                id: 20,
                nombre: 'W-12 Cerezo',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '7,5cm x 2,85mts',
                espesor: '1,4cm',
                img: 'Zocalos de PVC/W-12-web-baja.webp',
                link: 'w-12-cerezo-zocalo'
            },
            {
                id: 21,
                nombre: 'W-14 Roble Natural',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '7,5cm x 2,85mts',
                espesor: '1,4cm',
                img: 'Zocalos de PVC/W-14 baja web.jpg',
                link: 'w-14-roble-natural-zocalo'
            },
            {
                id: 22,
                nombre: 'WR-16 Petiribi ',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '7,5cm x 2,85mts',
                espesor: '1,4cm',
                img: 'Zocalos de PVC/WR-16 Petiribi Zocalo.jpg',
                link: 'wr-16-petiribi-zocalo'
            },
            {
                id: 23,
                nombre: 'W-17 Pino Natural',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '8cm x 2,85mts',
                espesor: '1,4cm',
                img: 'Zocalos de PVC/WR-17 Zocalo Pino Natural ALTA (1).jpg',
                link: 'wr-17-pino-natural-zocalo'
            },
            {
                id: 24,
                nombre: 'W-21 Gris Titanio',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '8cm x 2,85mts',
                espesor: '1,4cm',
                img: 'Zocalos de PVC/W-21-Zocalo-Gris-Titanio-1536x1024.webp',
                link: 'w21gristitaniozocalo'
            },
            {
                id: 25,
                nombre: 'CB',
                material: 'Poliuretano',
                simil: '',
                medidas: '3cm x 2,85mts',
                espesor: '1,3cm',
                img: 'Zocalos de PVC/cb_800x600-1.webp',
                link: 'cb'
            },
            {
                id: 26,
                nombre: 'CW-01 Blanca',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '3cm x 2,85mts',
                espesor: '1,3cm',
                img: 'Zocalos de PVC/CW-01-Blanco-compressor.webp',
                link: 'cw-01-blanco'
            },
            {
                id: 27,
                nombre: 'CW-04 Chocolate',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '3cm x 2,85mts',
                espesor: '1,3cm',
                img: 'Zocalos de PVC/CW-04-Chocolate-compressor.webp',
                link: 'cw-04-chocolate'
            },
            {
                id: 28,
                nombre: 'CW-05 Roble Cal',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '3cm x 2,85mts',
                espesor: '1,3cm',
                img: 'Zocalos de PVC/CW-05-Roble-Cal-compressor.webp',
                link: 'cw-05-roble-cal'
            },
            {
                id: 29,
                nombre: 'CW-06 Rustico',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '3cm x 2,85mts',
                espesor: '1,3cm',
                img: 'Zocalos de PVC/CW-06-Cuarta-Ca-aa-Rustico-1536x1024.webp',
                link: 'cw-06-rustico'
            },
            {
                id: 30,
                nombre: 'CW-08 Roble',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '3cm x 2,85mts',
                espesor: '1,3cm',
                img: 'Zocalos de PVC/cw08_roble_maple_800x600.webp',
                link: 'cw-08-roble-maple'
            },
            {
                id: 31,
                nombre: 'CW-10 Incienso',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '3cm x 2,85mts',
                espesor: '1,3cm',
                img: 'Zocalos de PVC/CW-10-Incienso-1-1-1536x1024.webp',
                link: 'cw-10-incienso'
            },
            {
                id: 32,
                nombre: 'CW-11 Vison',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '3cm x 2,85mts',
                espesor: '1,3cm',
                img: 'Zocalos de PVC/cw11_vision_800x600.webp',
                link: 'cw-11-vison'
            },
            {
                id: 33,
                nombre: 'CW-12 Cerezo',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '3cm x 2,85mts',
                espesor: '1,3cm',
                img: 'Zocalos de PVC/CW-12-Cerezo-compressor.webp',
                link: 'cw-12-cerezo'
            },

        ]
    },
    {
        id: 4,
        link: 'woodPanels',
        categoria: 'wood panels',
        descripcion: 'Para generar ambientes cálidos y modernos en cualquier tipo de pared o techo, contamos con ocho revestimientos simil madera de PVC. Su instalación requiere el uso de adhesivos, pero es rápida ya que se cortan de manera ágil y sencilla sin generar suciedad. Su mantenimiento es práctico y cómodo, generando un confort natural en habitaciones, livings, comedores y halls de entrada. Solamente un modelo es apto para un uso exterior que ya cuenta con una tecnología resistente al agua y a la exposición natural. Todos los listones vienen de 2.85 mts de largo y 12 cm de ancho. Cada color cuenta con una terminación específica, ya sea horizontal o vertical.',
        destacado: [
            {
                id: 1,
                nombre: 'resistente al agua',
                img: 'resistenteAlAgua.svg'
            },
            {
                id: 2,
                nombre: 'facil de instalar',
                img: 'FacilInstalar.svg'
            },
            {
                id: 3,
                nombre: 'Textura de madera',
                img: 'TexturaDeMadera.svg'
            },
            {
                id: 4,
                nombre: 'Apto techos y paredes',
                img: 'AptoTechosParedes.svg'
            }

        ],
        productos: [
            {
                id: 1,
                nombre: 'WP-01 Roble',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '120mm x 2850mm',
                espesor: '12mm',
                colores: '8',
                terminacion: [
                    {
                        nombre:'T-1 Roble',
                        img: 'terminaciones/t1.jpg'
                    }
                ],
                img: 'Wood Panels/WP-01 ROBLE.jpg',
                link: 'wp-01-roble'
            },
            {
                id: 2,
                nombre: 'WP-02 Incienso',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '120mm x 2850mm',
                espesor: '12mm',
                colores: '8',
                terminacion: [
                    {
                        nombre:'T-2 Incienso',
                        img: 'terminaciones/t2.jpg'
                    }
                ],
                img: 'Wood Panels/WP-02 INCIENSO.jpg',
                link: 'wp-02-incienso'
            },
            {
                id: 3,
                nombre: 'WP-03 Roble y Negro',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '120mm x 2850mm',
                espesor: '12mm',
                colores: '8',
                terminacion: [
                    {
                        nombre:'T-1 Roble',
                        img: 'terminaciones/t1.jpg'
                    }
                ],
                img: 'Wood Panels/WP-03 ROBLE Y NEGRO.jpg',
                link: 'wp-03-roble-y-negro'
            },
            {
                id: 4,
                nombre: 'WP-04 Blanco',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '120mm x 2850mm',
                espesor: '12mm',
                colores: '8',
                terminacion: [
                    {
                        nombre:'T-3 Blanco',
                        img: 'terminaciones/t3.jpg'
                    }
                ],
                img: 'Wood Panels/WP-04 BLANCO.jpg',
                link: 'wp-04-blanco'
            },
            {
                id: 5,
                nombre: 'WP-05 Roble',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '120mm x 2850mm',
                espesor: '21mm',
                colores: '8',
                terminacion: [
                    {
                        nombre:'T-4',
                        img: 'terminaciones/t4.jpg'
                    },
                    {
                        nombre:'T-5',
                        img: 'terminaciones/t5.jpg'
                    }
                ],
                img: 'Wood Panels/WP-05 ROBLE.jpg',
                link: 'wp-05-roble'
            },
            {
                id: 6,
                nombre: 'WP-06 Incienso',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '120mm x 2850mm',
                espesor: '21mm',
                colores: '8',
                terminacion: [
                    {
                        nombre:'T-6',
                        img: 'terminaciones/t6.jpg'
                    },
                    {
                        nombre:'T-7',
                        img: 'terminaciones/t7.jpg'
                    }
                ],
                img: 'Wood Panels/WP-06 INCIENSO.jpg',
                link: 'wp-06-incienso'
            },
            {
                id: 7,
                nombre: 'WP-07 INCIENSO',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '120mm x 2850mm',
                espesor: '12mm',
                colores: '8',
                terminacion: [
                    {
                        nombre:'T-2 Incienso',
                        img: 'terminaciones/t2.jpg'
                    }
                ],
                img: 'Wood Panels/WP-07 INCIENSO.jpg',
                link: 'wp-08-roble'
            },
            {
                id: 8,
                nombre: 'WP-08 Roble',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '120mm x 2850mm',
                espesor: '12mm',
                colores: '8',
                terminacion: [
                    {
                        nombre:'T-1 Roble',
                        img: 'terminaciones/t1.jpg'
                    }
                ],
                img: 'Wood Panels/WP-08 ROBLE.jpg',
                link: 'wp-08-roble'
            },
            {
                id: 9,
                nombre: 'WP-09 ABETO ',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '120mm x 2850mm',
                espesor: '12mm',
                colores: '8',
                terminacion: [
                    {
                        nombre:'T-12 Abeto',
                        img: 'terminaciones/t12.jpg'
                    }
                ],
                img: 'Wood Panels/WP-09 AVETO.jpg',
                link: 'wp-09-abeto'
            },
            {
                id: 10,
                nombre: 'WP-10 ROBLE CAL',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '120mm x 2850mm',
                espesor: '12mm',
                colores: '8',
                terminacion: [
                    {
                        nombre:'T-11 Roble',
                        img: 'terminaciones/t11.jpg'
                    }
                ],
                img: 'Wood Panels/WP-10 ROBLE CAL.jpg',
                link: 'wp-10-roble-cal'
            },
            {
                id: 11,
                nombre: 'WP-11 LIGHT TIP',
                material: 'Poliuretano',
                simil: 'Madera',
                medidas: '120mm x 2850mm',
                espesor: '12mm',
                colores: '8',
                terminacion: [
                    {
                        nombre:'T-9',
                        img: 'terminaciones/t9.jpg'
                    },                    {
                        nombre:'T-10',
                        img: 'terminaciones/t10.jpg'
                    }
                ],
                img: 'Wood Panels/WP-11 LIGHT TIP.jpg',
                link: 'wp-11-light-tip'
            },
        ]
    },
    {
        id: 5,
        link: 'giantWallPanels',
        categoria: 'giant wall panels',
        descripcion: 'Con diseños únicos en mármol y piedra de vanguardia mundial los paneles de PVC son la solución ideal para el revestimiento de paredes de baños, cocinas, livings y espacios de trabajo de tu casa u oficina. Son ideales para la renovación de espacios o el emprendimiento de nuevos diseños. Debido a la nueva tecnología que poseen, su instalación es rápida, sencilla y limpia, con el objetivo de evitar llevar a cabo una obra a largo plazo. A diferencia de una placa tradicional, este producto innovador es resistente al agua y a la humedad, siendo también fácil de mantener. Brindando un aspecto único las placas cuentan con 1,22 mts de ancho x 2.6 mts de alto, siendo así las mas grandes del mercado y únicas en el país. Por su distinguido acabado espejado, su terminación luce moderna, brillante y elegante.',
        destacado: [
            {
                id: 1,
                nombre: 'resistente al agua',
                img: 'resistenteAlAgua.svg'
            },
            {
                id: 2,
                nombre: 'facil de instalar',
                img: 'FacilInstalar.svg'
            },
            {
                id: 3,
                nombre: 'acabado brillante',
                img: 'AcabadoBrillante.svg'
            },
            {
                id: 4,
                nombre: 'tamaño amplio',
                img: 'TamanosAmplios.svg'
            }

        ],
        productos: [
            {
                id: 2,
                nombre: 'M-02 IVORY',
                material: '100% pvc virgen',
                simil: 'Cemento',
                medidas: '1220mm x 2600mm',
                espesor: '3mm',
                img: 'Giant Wall Panels/M-02 Ivory.jpg',
                link: 'm-02-ivory'

            },
            {
                id: 4,
                nombre: 'M-04 CARRARA',
                material: '100% pvc virgen',
                simil: 'Marmol',
                medidas: '1220mm x 2600mm',
                espesor: '3mm',
                img: 'Giant Wall Panels/M-04 Carrara.jpg',
                link: 'm-04-carrara'

            },
            {
                id: 5,
                nombre: 'M-05 MARRÓN EMPERADOR',
                material: '100% pvc virgen',
                simil: 'Marmol',
                medidas: '1220mm x 2600mm',
                espesor: '3mm',
                img: 'Giant Wall Panels/M-05 Marron Emperador.jpg',
                link: 'm-05-marron-emperador'

            },
            {
                id: 6,
                nombre: 'M-06 MARQUINA',
                material: '100% pvc virgen',
                simil: 'Marmol',
                medidas: '1220mm x 2600mm',
                espesor: '3mm',
                img: 'Giant Wall Panels/M-06 Marquina.jpg',
                link: 'm-06-marquina'
            },
            {
                id: 7,
                nombre: 'M-07 CALACATTA',
                material: '100% pvc virgen',
                simil: 'Marmol',
                medidas: '1220mm x 2600mm',
                espesor: '3mm',
                img: 'Giant Wall Panels/M-07 Calacatta.jpg',
                link: 'm-07-calacatta'
            },
            {
                id: 8,
                nombre: 'M-08 CALACATTA GOLD',
                material: '100% pvc virgen',
                simil: 'Marmol',
                medidas: '1220mm x 2600mm',
                espesor: '3mm',
                img: 'Giant Wall Panels/M-08 Calacatta Gold.jpg',
                link: 'm-08-calacatta-gold'
            },
            {
                id: 9,
                nombre: 'M-09 TRAVERTINO',
                material: '100% pvc virgen',
                simil: 'Marmol',
                medidas: '1220mm x 2600mm',
                espesor: '3mm',
                img: 'Giant Wall Panels/M-09 TRAVERTINO.png',
                link: 'm-09-travertino'
            },
            {
                id: 10,
                nombre: 'M-10 TUNDRA GRAY',
                material: '100% pvc virgen',
                simil: 'Marmol',
                medidas: '1220mm x 2600mm',
                espesor: '3mm',
                img: 'Giant Wall Panels/M-10 TUNDRA GREY.jpeg',
                link: 'm-10-tundra-gray'
            },
            {
                id: 11,
                nombre: 'M-11 ROYAL BEIGE',
                material: '100% pvc virgen',
                simil: 'Marmol',
                medidas: '1220mm x 2600mm',
                espesor: '3mm',
                img: 'Giant Wall Panels/M-11 ROYAL BEIGE.jpg',
                link: 'm-11-royal-beige'
            },
            
        ]
    },
    {
        id: 6,
        link: 'sidingdeexterior',
        categoria: 'Siding WPC exterior',
        descripcion: 'Nuestros revestimientos de pared de uso exterior dan la sensación de una madera natural y texturada, siendo un material especialmente diseñado para soportar humedad, lluvia y distintas temperaturas. Su alta resistencia a la intemperie lo hace un producto de fácil mantenimiento. Para la instalación no se requieren herramientas especiales, por lo tanto nuestro producto logra rápidamente ser una solución y un cambio estético en el ambiente que se coloca.',
        destacado: [
            {
                id: 1,
                nombre: 'resistente a la intemperie',
                img: 'resistente a la intemperie.svg'
            },
            {
                id: 2,
                nombre: 'facil de instalar',
                img: 'FacilInstalar.svg'
            },
            {
                id: 3,
                nombre: 'facil mantenimiento',
                img: 'facil mantenimiento.svg'
            },
            {
                id: 4,
                nombre: 'textura madera',
                img: 'TexturaDeMadera.svg'
            }

        ],
        productos: [
            {
                id: 1,
                nombre: 'EWP-01 Nogal',
                material: 'WPC',
                simil: 'Madera',
                medidas: '120mm x 2850mm',
                espesor: '20,5mm',
                terminacion: [
                    {
                        nombre:'A-1 Apoyo',
                        img: 'terminaciones/a1.jpg'
                    },                    {
                        nombre:'T-8',
                        img: 'terminaciones/t8.jpg'
                    }
                ],
                img: 'Siding Exterior/EWP01.jpg',
                link: 'ewp-01-nogal'
            },
            {
                id: 2,
                nombre: 'EWP-02 Teka',
                material: 'WPC',
                simil: 'Madera',
                medidas: '120mm x 2850mm',
                espesor: '26mm',
                terminacion: [
                    {
                        nombre:'A-1 Apoyo',
                        img: 'terminaciones/a1.jpg'
                    },                    {
                        nombre:'T-13',
                        img: 'terminaciones/t13.jpg'
                    }
                ],
                img: 'Siding Exterior/EWP-02 TEKA.jpg',
                link: 'ewp-02-teka'

            },
            {
                id: 3,
                nombre: 'EWP-03 Teka',
                material: 'WPC',
                simil: 'Madera',
                medidas: '120mm x 2850mm',
                espesor: '20,5mm',
                terminacion: [
                    {
                        nombre:'A-1 Apoyo',
                        img: 'terminaciones/a1.jpg'
                    },                    {
                        nombre:'T-14',
                        img: 'terminaciones/t14.jpg'
                    }
                ],
                img: 'Siding Exterior/EWP-03 TEKA.jpg',
                link: 'ewp-03-teka'

            },
        ]
    },

]

export function Data() {
    return (db)
}
export default function DataFind(p) {
    return (db.find(parametro => parametro.link === p))
}
export function DataFindProduct(c, p) {
    const categoria = db.find(parametro => parametro.link === c)
    return (categoria.productos.find(productos => productos.id.toString() === p))
}