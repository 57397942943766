import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


export default function ProductDetail({children}) {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          paritialVisibilityGutter: 30
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          paritialVisibilityGutter: 50
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          paritialVisibilityGutter: 130
        }
      };

return (
<Carousel
      ssr
      partialVisbile
      itemClass="image-item"
      responsive={responsive}
      swipeable={true}
>
{children}
</Carousel>

)}
