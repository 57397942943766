import React, { useState } from 'react'
import classes from './navbar.module.css'
import { Link, NavLink } from 'react-router-dom'
import { Data } from '../../db/db'


export default function Navbar() {
    const [sideBar, setSideBar] = useState(false)
    const handleSideBar = () => setSideBar(!sideBar)
    const db = Data()

    return (
        <nav>
            <div className={classes.navWidth}>
                <div onClick={(sideBar) => sideBar ? { handleSideBar } : ''} className={sideBar ? `${classes.container} ${classes.cActive}` : `${classes.container}`}>
                    <div>
                        <Link to='/'>
                            <img className={classes.logo} src="/img/logo-blanco.png" alt="Logo Yuka" />
                        </Link>
                    </div>
                    <div>
                        <div onClick={handleSideBar} className={classes.contIcon}>
                            <div className={sideBar ? `${classes.topBar} ${classes.topBarActive}` : `${classes.topBar}`}></div>
                            <div className={sideBar ? `${classes.bottomBar} ${classes.bottomBarActive}` : `${classes.bottomBar}`}></div>
                        </div>
                    </div>
                </div>
                <div onClick={handleSideBar} className={sideBar ? `${classes.links} ${classes.active}` : `${classes.links}`}>
                    <ul>
                        {
                            db.map((l) =>
                                <li key={l.id}>
                                    <NavLink className={({ isActive }) => isActive ? `${classes.linkActive} ` : `${classes.link}`} to={`/${l.link}`}>{l.categoria}</NavLink>
                                </li>
                            )
                        }
                    </ul>

                </div>
            </div>

        </nav>
    )
}