import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classes from './product.module.css'
import { useParams } from 'react-router-dom';
import DataFind from '../db/db'

export default function Product() {
    const params = useParams();
    const categoria = DataFind(params.categoria);
    const [active, setActive] = useState(false);
    const handleActive = () => setActive(!active)
    console.log(active)

    return (

        <div className={classes.contenedor}>
            <div className={classes.flexHeader}>
                <div className={classes.contTitular}>
                    <div className={classes.titular}>
                        <h1>{categoria.categoria}</h1>
                    </div>
                    <div onClick={handleActive}>
                        {
                            active ? <Link className={classes.btnExpand} to=''>-</Link> : <Link className={classes.btnExpand} to=''>+</Link>
                        }
                        
                    </div>
                
                <div className={active ? `${classes.infoSec} ${classes.active}` : `${classes.infoSec}`} >
                    <p>
                        {categoria.descripcion}
                    </p>
                    </div>
                </div>
                <div className={classes.destacados}>
                    {
                        categoria.destacado.map((d) =>

                            <div className={classes.destacado} key={d.id}>
                                <img src={`/icons/${d.img}`} alt="Icon" />
                                <h3>{d.nombre}</h3>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className={classes.productos}>

                {
                    categoria.productos.map((d) =>

                        <Link className={classes.pDestacado} key={d.id} to={d.id.toString()}>
                                {
                                 d.img === '' ? <img src={'/img/footer-img.png'} alt="sin foto"/> : <img src={`${d.img}`} alt="foto producto" />
                                }      
                            <h3>{d.nombre}</h3>
                        </Link>
                    )
                }

            </div>
        </div>

    )
}
