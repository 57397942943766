import React from 'react';
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import classes from './productDetail.module.css';
import DatoFind, { DataFindProduct } from '../db/db'
import Carousel from '../components/carousel/Carousel'
import { Image } from "semantic-ui-react";

// import Slider from '../components/slider/Slider';


export default function ProductDetail() {
  const params = useParams();
  const producto = DataFindProduct(params.categoria, params.productId);
  const listProducto = DatoFind(params.categoria);
  const paramsSection = listProducto.link;
  const combinacion = producto.combinacion
  const terminaciones= producto.terminacion
  const navigate = useNavigate()
  const goBack = () => {
		navigate(-1);
	}

  console.log('gggg', terminaciones)
  // console.log(producto.terminacion)
  return (
    <>
      <div className={classes.contenedor}>
        <div>
        <button className={classes.btn} onClick={goBack}> 
        Back  </button>
          <img className={classes.prodImg} src={`/${producto.img}`} alt="foto producto" />
          {/* <Slider imagenes={[`/${producto.img}`,`/${producto.treminacion}`]}/> */}
          {/* {
            producto.img === '' ? <img className={classes.prodImg} src={'/img/footer-img.png'} alt="sin foto" /> : <img className={classes.prodImg}  src={`/${producto.img}`} alt="foto producto" />
          } */}
        </div>
        <div className={classes.contInfo}>
          <div>
            <h2 className={classes.nombreProd}>{producto.nombre}</h2>
            <ul className={classes.infoProd}>
              <li>
                <span>Material </span>{producto.material}
              </li>
              {
                producto.simil === '' ? '' : <li>
                  <span>Simil </span>{producto.simil}
                </li>
              }
              <li>
                <span>Medidas </span>{producto.medidas}
              </li>
              <li>
                <span>Espesor </span>{producto.espesor}
              </li>
              {
                producto.capaUso === undefined ? '' : <li>
                  <span>Capa de uso </span>{producto.capaUso}
                </li>
              }

            </ul>
          </div>
          <div className={classes.contPR}>
            <h2 className={classes.nombreProd}>Productos Relacionados</h2>
           
            <Carousel>
              {
                listProducto.productos.map((d) =>

                  <Link className={classes.pRelacionados} key={d.id} to={`../${paramsSection}/${d.id.toString()}`} >
                    {
                       d.img === '' ? <img src={'/img/footer-img.png'} alt="sin foto" /> :
                               <Image
                               draggable={false}
                               src={`/${d.img}`}
                             />
                      // d.img === '' ? <img src={'/img/footer-img.png'} alt="sin foto" /> : <img src={`/${d.img}`} alt="foto producto" />
                    }
                    <h3>{d.nombre}</h3>
                  </Link>
                )
              }
            </Carousel>
           
          </div>
          {
            combinacion === undefined ? '' :

              <div className={classes.contPR}>
                <h2 className={classes.nombreProd}>Zocalos para combinar</h2>
                <div className={classes.productos}>
                  {
                    combinacion.map((d, index) =>
                      <div key={index} className={classes.pRelacionados} >
                        {
                          d.img === undefined ? <img src={'/img/footer-img.png'} alt="sin foto" /> : <img src={`/${d.img}`} alt="foto producto" />
                        }
                        <h3>{d.nombre}</h3>
                      </div>
                    )
                  }
                </div>
              </div>
          }
          {
            terminaciones === undefined ? '' :

              <div className={classes.contPR}>
                <h2 className={classes.nombreProd}>Terminaciones</h2>
                <div className={classes.productos}>
                  {
                    terminaciones.map((d, index) =>
                      <div key={index} className={classes.pRelacionados} >
                        {
                          d.img === undefined ? <img src={'/img/footer-img.png'} alt="sin foto" /> : <img src={`/${d.img}`} alt="foto producto" />
                        }
                        <h3>{d.nombre}</h3>
                      </div>
                    )
                  }
                </div>
              </div>
          }
        </div>
      </div>
    </>

  )
}
// export default function ProductDetail() {
//   const params = useParams();
//   const producto = DataFindProduct(params.categoria, params.productId);
//   const listProducto = DatoFind(params.categoria);
//   const paramsSection = listProducto.link;
//   const combinacion = producto.combinacion


//   // console.log('gggg', combinacion)
//   // console.log(producto.terminacion)
//   return (
//     <>
//       <div className={classes.contenedor}>
//         <div>
//           <img className={classes.prodImg} src={`/${producto.img}`} alt="foto producto" />
//           {/* <Slider imagenes={[`/${producto.img}`,`/${producto.treminacion}`]}/> */}
//           {/* {
//             producto.img === '' ? <img className={classes.prodImg} src={'/img/footer-img.png'} alt="sin foto" /> : <img className={classes.prodImg}  src={`/${producto.img}`} alt="foto producto" />
//           } */}
//         </div>
//         <div className={classes.contInfo}>
//           <div>
//             <h2 className={classes.nombreProd}>{producto.nombre}</h2>
//             <ul className={classes.infoProd}>
//               <li>
//                 <span>Material </span>{producto.material}
//               </li>
//               {
//                 producto.simil === '' ? '' : <li>
//                   <span>Simil </span>{producto.simil}
//                 </li>
//               }
//               <li>
//                 <span>Medidas </span>{producto.medidas}
//               </li>
//               <li>
//                 <span>Espesor </span>{producto.espesor}
//               </li>
//               {
//                 producto.capaUso === undefined ? '' : <li>
//                   <span>Capa de uso </span>{producto.capaUso}
//                 </li>
//               }

//             </ul>
//           </div>
//           <div className={classes.contPR}>
//             <h2 className={classes.nombreProd}>Productos Relacionados</h2>
//             <div className={classes.productos}>

//               {
//                 listProducto.productos.map((d) => 

//                   <Link className={classes.pRelacionados} key={d.id} to={`../${paramsSection}/${d.id.toString()}`} >
//                     {
//                       d.img === '' ? <img src={'/img/footer-img.png'} alt="sin foto" /> : <img src={`/${d.img}`} alt="foto producto" />
//                     }
//                     <h3>{d.nombre}</h3>
//                   </Link>
//                 )
//                 }

//             </div>
//           </div>
//           {
//             combinacion === undefined ? '' :

//               <div className={classes.contPR}>
//                 <h2 className={classes.nombreProd}>Zocalos para combinar</h2>
//                 <div className={classes.productos}>
//                   {
//                     combinacion.map((d, index) =>
//                       <div key={index} className={classes.pRelacionados} >
//                         {
//                           d.img === undefined ? <img src={'/img/footer-img.png'} alt="sin foto" /> : <img src={`/${d.img}`} alt="foto producto" />
//                         }
//                         <h3>{d.nombre}</h3>
//                       </div>
//                     )
//                   }
//                 </div>
//               </div>
//           }
//         </div>
//       </div>
//     </>

//   )
// }