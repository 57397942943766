import React from 'react'
import classes from './footer.module.css'

export default function Footer() {
  return (
    <footer>
        <section>
        <h1 className={classes.ftitle}>
            Feel <br /> <span> the floor</span>
        </h1>
        </section>
        <section>
            <div className={classes.icons}>
                <div className={classes.redes}>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/yukahomes/"><img src="/img/facebook-icon.svg" alt="icono facebook" /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/yukahomes/"><img src="/img/instagram-icon.svg" alt="icono instagram" /></a>
                </div>
                <div>
                    <a className={classes.mail} href='mailto: info@yukahomes.com'>info@yukahomes.com</a>
                </div>

            </div>
        </section>
    </footer>
  )
}
